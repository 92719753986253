* {
    padding: 0;
    margin: 0;
}
header {
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: #007853;
    height: 115px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    h1 {
        font-weight: 100;
    }
}
h2 {
    color: #007853;
    span {
        font-weight: 900;
    }
}
h3 {
    color: #ccc;
    span {

        font-weight: 900;
    }
}

.pointer {
    cursor: pointer;
}
body{
    background-color: #ecf0f1
}
.avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    img {
        width: 100%;
        height: 100%;
    }
}

input[type='radio']:before {
    content:'';
    display:block;
    width:100%;
    height:100%;
    /*margin: 20% auto;    */
    border-radius:50%; 
    margin-bottom: -4px;
    margin-right: 10px;
    border: 2px solid   green; 
}
input[type='radio'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border:1px solid darkgray;
    border-radius:50%;
    outline:none;
    margin-bottom: -4px;
    margin-right: 10px;
    box-shadow:0 0 5px 0px gray inset;
}
input[type='radio']:hover {
    box-shadow:0 0 5px 0px orange inset;
}
input[type='radio']:before {
    content:'';
    display:block;
    width:100%;
    height:100%;
    /*margin: 20% auto;    */
    border-radius:50%;    
}
input[type='radio']:checked:before {
    background:green;
}

button {
    border: 0;
    &.basic {
        padding: 10px 35px;
        border-radius: 28px;
        background-color: #007853;
        color: #fff;
        font-weight: 100;
        font-size: 16px;
    }
    &.modify {
        padding: 10px 35px;
        border-radius: 20px;
        border: 2px solid grey;
        color: grey;
        text-transform: uppercase;
        background: transparent;
        font-weight: 500;
        font-size: 16px;
    }
    &.transparent {
        background: transparent;
        color: #009866;
        font-size: 14px;
        font-weight: 500;
        div {
            font-weight: 400;
            font-size: 10px;
            border-top: 1px solid #009866;
        }
    }
}

.tag {
    padding: 10px 35px;
    border-radius: 28px;
    background-color: #007853;
    color: #fff;
    font-weight: 900;
    display: inline-block;
    font-size: 18px;
}

:focus-visible {
    outline: 0;
}

.control {
    margin-bottom: 20px;
    >div {
        text-align: left;
        font-size: 11px;
        color: #6f6f6f;
    }
    input {
        background-color: transparent;
        border:0;
        width: 100%;
        border-bottom: 1px solid #6f6f6f;
    }
}